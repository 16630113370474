import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import Courses from './components/courses';
import Course from './components/course';
import Contact from "./components/contact";
import Booking from "./components/booking";
import Contacts from "./components/contacts";
import Bookings from './components/bookings';
import EditPage from './components/editpage';
import Press from './components/press';
import Testimonials from './components/testimonials';
import Internationals from './components/internationals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/bookings' element={<Bookings />} />
      <Route path='/bookings/:id' element={<Booking />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/contacts/:id' element={<Contact />} />
      <Route path='/courses' element={<Courses />} />
      <Route path='/courses/:id' element={<Course />} />
      <Route path='/edit-home' element={<EditPage page="home" />} />
      <Route path='/edit-home-testimonials' element={<Testimonials />} />
      <Route path='/edit-home-testimonials/:id' element={<Testimonials />} />
      <Route path='/edit-about' element={<EditPage page="about" />} />
      <Route path='/edit-terms' element={<EditPage page="terms" />} />
      <Route path='/edit-privacy' element={<EditPage page="privacy" />} />
      <Route path='/press-blog' element={<Press />} />
      <Route path="/internationals" element={<Internationals />} />
      <Route path="/internationals/:id" element={<Internationals />} />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

