import { useState, useEffect, useRef } from "react";
import Menu from "./menu";

const Press = () => {
const [press, setPress] = useState(false);
const title = useRef(); const heading = useRef(); const pbutton = useRef();
const dp = useRef(); const content = useRef();
const [refresh, setRefresh] = useState(false);
useEffect(() => {
    fetch('https://api.thevoicebusiness.com.au/press', {
        headers: {'content-type':'application/json'}
    }).then(res => res.json())
    .then(jr => setPress(jr));
}, [refresh]);

const fetchpress = (id) => {
    fetch('https://api.thevoicebusiness.com.au/press/'+id, { credentials: 'include',
        headers: {'content-type':'application/json'}
    }).then(res => res.json())
    .then(jr => {
        title.current.value = jr.title; heading.current.value = jr.heading;
        content.current.value = jr.content; pbutton.current.innerHTML = 'Update';
        pbutton.current.dataset.id = jr.id;
    });
}

const savepress = (e) => {
    let method = ''; let url = '';
    if (e.target.innerHTML === 'Update') {
        method = 'put'; url = 'https://api.thevoicebusiness.com.au/press/'+e.target.dataset.id;
    } else {
        method = 'post'; url = 'https://api.thevoicebusiness.com.au/press';
    }
    e.target.innerHTML = 'Saving...';
    const formData = new FormData();
    formData.append('title', title.current.value);
    formData.append('heading', heading.current.value);
    formData.append('content', content.current.value);
    if(dp.current.files.length > 0) { formData.append('dp', dp.current.files[0]); }
    fetch(url, { credentials: 'include', body: formData, method: method })
    .then(_ => { setRefresh(true); content.current.value = '';
        title.current.value = ''; heading.current.value = ''; dp.current.value = '';
        setRefresh(!refresh); e.target.innerHTML = 'Saved';
        setTimeout(() => e.target.innerHTML = 'Save Press / Blog', 1000);
    });
}

return <div className="row"><div className="col-md-2"><Menu /></div>
<div className="col-md-10 mt-3"><h4 className="mb-3">Press / Blog</h4>
<div className="row">
    <div className="col-md-4">
        <div className="form-group">
            <label>Heading</label>
            <input type="text" className="form-control" ref={heading} />
        </div>
    </div>
    <div className="col-md-4">
        <div className="form-group">
            <label>Title</label>
            <input type="text" className="form-control" ref={title} />
        </div>
    </div>
    <div className="col-md-4">
    <div className="form-group">
        <label>Upload Display image</label>
        <input type="file" className="form-control" ref={dp} />
    </div>
    </div>
    </div>
    <div className="row my-2">
    <div className="col-md-8">
        <div className="form-group">
            <label>Content</label>
            <textarea className="form-control" ref={content}></textarea>
        </div>
    </div>
    <div className="col-md-4">
        <button ref={pbutton} className="btn btn-primary mt-5" onClick={savepress}>Save Press / Blog</button>
    </div>
    </div>
    <hr />
{(press) ?
<table className="table">
<thead><tr><th>Heading</th><th>Title</th><th>Published</th><th>Actions</th></tr></thead>
<tbody>{
press.map(el => <tr key={el.id}><td>{el.heading}</td><td>{el.title}</td>
<td>{new Date(el.createdAt).toDateString()}</td>
<td><button className="btn btn-outline-primary" onClick={() => fetchpress(el.id)}>Update</button>
</td></tr>)
}</tbody>
    </table>: <p>Loading...</p>}
</div>
    </div>
}

export default Press;