import { useEffect, useState } from "react";
import Menu from "./menu";
import SunEditor from 'suneditor-react';
import Pagecontent from './pagecontent';

const Editpage = ({ page }) => {
  const [cont, setCont] = useState(null);

  const updatecontent = (x) => { let xyz = {...cont}; xyz.content = x; setCont(xyz); }

  const savePage = () => {
    const id = cont.id; const val = {...cont}; delete val.id; delete val.urlslug;
    if (typeof(val.content) === 'object') { val.content = JSON.stringify(val.content); }
    fetch('https://api.thevoicebusiness.com.au/page/'+id, {credentials: 'include',
    headers: {'content-type': 'application/json'}, method: 'put', body: JSON.stringify(val)})
    .then(res => res.json()).then(jr => alert('Saved'));
  }

  useEffect(() => {
    setCont(null);
    fetch('https://api.thevoicebusiness.com.au/page/' + page)
      .then(r => r.json())
      .then(j => {
        try {
            j.content = JSON.parse(j.content);
        } catch { // do nothing
        }
        setCont(j)
    });
  }, [page]);

  if (!cont) return <div className="row">
  <div className="col-md-2">
    <Menu />
  </div><div className="col-md-10 mt-3">loading...</div></div>;

  return (
    <div className="row">
      <div className="col-md-2">
        <Menu />
      </div>
      <div className="col-md-10">
        <div className="mt-3">
          <h4>{cont.title}</h4>
        </div>
        <hr />
        <div className="form-group">
          <label htmlFor="tit">Title of the page</label>
          <input 
            type="text" 
            id="tit" 
            className="form-control" 
            defaultValue={cont.title} 
            onChange={(e) => { const x = {...cont}; x.title = e.target.value; setCont(x);} }
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description of the page</label>
          <input 
            type="text" 
            id="description" 
            className="form-control" 
            defaultValue={cont.description} 
            onChange={(e) => setCont((x) => {return {...x, description: e.target.value}})}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="keyboard">Keywords of the page</label>
          <input 
            type="text" 
            id="keyboard" 
            className="form-control" 
            defaultValue={cont.keywords} 
            onChange={(e) => setCont((x) => {return {...x, keywords: e.target.value}})}
          />
        </div>
        { (typeof(cont.content) === 'object') ?
        Object.keys(cont.content).map(
          (el, ea) => <Pagecontent key={ea} el={el} ea={ea}
          content={cont.content} setcontent={setCont} />
        ) : <SunEditor defaultValue={cont.content} onChange={updatecontent} />
        }
        <div className="my-2">
          <button className="btn btn-primary" onClick={savePage}>Save page</button>
        </div>
      </div>
    </div>
  );
};

export default Editpage;
