import { Link, useParams } from "react-router-dom";
import Menu from "./menu";
import { useEffect, useState } from "react";

const Booking = () => {
const {id} = useParams();
const [booking, setBooking] = useState(false);
useEffect(() => {
    if (booking === false) {
        fetch('https://api.thevoicebusiness.com.au/bookings/'+id, {credentials: 'include'})
    .then(res => res.json()).then(jr => setBooking(jr));
    }
}, []);
return <div className="row">
<div className="col-md-2">
    <Menu />
    </div>
<div className="col-md-10">
    <div className="row">
    {(!booking) ? <p>Loading...</p> :
    <div className="col-md-9">
        <p className="text-end"><Link to="/bookings">Back to Bookings</Link></p>
        <h5>Contacted on : {new Date(booking.createdAt).toLocaleString()}</h5>
    <table className="table table-bordered table-hover mt-3">
        <tbody>
        <tr><th>First name</th><td>{booking.firstname}</td></tr>
        <tr><th>Last name</th><td>{booking.lastname}</td></tr>
        <tr><th>Course</th><td>{booking.course.title}</td></tr>
        <tr><th>Mobile</th><td>{booking.mobile}</td></tr>
        <tr><th>Email</th><td>{booking.email}</td></tr>
        <tr><th>Payment Option</th><td>{booking.payment_option}</td></tr>
        <tr><th>Preferred Start Date</th><td>{new Date(booking.startdate).toDateString()}</td></tr>
        <tr><th>Company</th><td>{booking.company}</td></tr>
        <tr><th>Job title</th><td>{booking.jobtitle}</td></tr>
        <tr><td colSpan={2} className="mx-auto fw-bold text-center py-3">Billing Details</td></tr>
        <tr><th>Billing address</th><td>{booking.billing_address}</td></tr>
        <tr><th>Billing city</th><td>{booking.billing_city}</td></tr>
        <tr><th>Billing state</th><td>{booking.billing_state}</td></tr>
        <tr><th>Billing postcode</th><td>{booking.billing_postcode}</td></tr>
        <tr><th>Billing country</th><td>{booking.billing_country}</td></tr>
        <tr><td colSpan={2} className="mx-auto fw-bold text-center py-3">Other Information</td></tr>
        <tr><th>Home phone</th><td>{booking.homephone}</td></tr>
        <tr><th>Work phone</th><td>{booking.workphone}</td></tr>
        <tr><th>Newsletter Signup?</th><td>{(booking.newsletter) ? 'Yes' : 'No'}</td></tr>
        <tr><th>Comments</th><td>{booking.comments}</td></tr>
        <tr><th>Reference</th><td>{booking.course_reference}</td></tr>
        </tbody>
    </table>
    </div>
    }
    </div>
</div>
</div>}

export default Booking;