const Login = (props) => {
    const login = () => {
        Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
        let un = document.getElementById('username');
        let pw = document.getElementById('password');
        if (un.value.length < 1) {
            return un.classList.add('is-invalid');
        }
        if (pw.value.length < 1) {
            return pw.classList.add('is-invalid');
        }
        fetch('https://api.thevoicebusiness.com.au/login', {
            headers: {'Content-Type':'application/json'}, credentials: 'include',
            method: 'post',
            body: JSON.stringify({username: un.value, password: pw.value})
        }).then(res => res.json())
        .then(jr => {
            if (jr.success) {
                props.retry(true); return true;
            } else { un.classList.add('is-invalid'); pw.classList.add('is-invalid'); }
        })
        ;
    };
    return <div className="mt-5 row">
    <div className="col-12 col-md-4 mx-auto">
        <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" className="form-control" />
        </div>
        <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input id="password" type="password" className="form-control" />
        </div>
        <div className="mt-3">
            <button className="btn btn-primary" onClick={() => login()}>Login</button>
        </div>
    </div>
</div>}

export default Login;