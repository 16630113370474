import { useRef } from "react";

const Ct = (props) => {
    const testimonial = useRef();
    const author = useRef();
    const saveTes = () => {
        if (props.id == 0) {
            
        }
    }
    return <div className='my-3'>
    <div className='form-group'>
    <label>Testimonial</label>
    <input type='text' className='form-control' ref={testimonial} defaultValue={props.testimonial} />
    </div>
    <div className="form-group">
    <label>Name</label>
    <input type="text" className="form-control" ref={author} defaultValue={props.name} />
    </div>
    <button className='btn btn-primary my-3' onClick={() => saveTes()}>Save Testimonial</button></div>
}

export default Ct;