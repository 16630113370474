import { useEffect, useState } from "react";
import Login from "./components/login";
import { Link } from "react-router-dom";

function App() {
  const [loggedin, setLoggedin] = useState(false);
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    fetch('https://api.thevoicebusiness.com.au/check-login', {credentials: 'include', headers: {
      'Content-type':'application/json'}})
  .then(res => (res.status === 200) ? res.json() : false)
  .then((jr) => {
    if (jr.success) {
      setLoggedin(true);
    }
  });
  }, [retry]);
  return <>
  {(loggedin) ? <p>You are logged in. Go to <Link to="/bookings">Bookings</Link></p> :
  <Login retry={setRetry} /> }
  </>;
}

export default App;
