import SunEditor from "suneditor-react";
import Menu from "./menu";
import { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export default () => {
const [content, setContent] = useState();
const title = useRef();
const picture = useRef();
const urls = useRef();
const [testimonials, setTestimonials] = useState([]);
const {id} = useParams();

const deletepage = () => { alert('Cannot process delete. Operation is forbidden on the server') }

const saved = () => { alert('Saved'); window.location.href = '/internationals'; }

const savepage = () => {
    const fd = new FormData();
    fd.append('details', content);
    fd.append('name', title.current.value);
    fd.append('url', urls.current.value);
    if (picture.current.files.length > 0) {
        fd.append('picture', picture.current.files[0]);
    }
    const method = (id) ? 'PUT' : 'POST';
    const url = (id) ? `/${id}` : '';
    fetch('https://api.thevoicebusiness.com.au/internationals'+url, {
        method: method, body: fd, credentials: 'include'
    }).then(r => saved());
}

useEffect(() => {
    if (!id) return;
    fetch('https://api.thevoicebusiness.com.au/internationals/'+id)
    .then(r => r.json()).then(jr => {
        title.current.value = jr.name; setContent(jr.details);
        urls.current.value = jr.url;
    });
    window.scroll(0,0);
}, [id]);

useEffect(() => {
    fetch('https://api.thevoicebusiness.com.au/internationals').then(r => r.json())
    .then(jr => setTestimonials(jr));
}, [])

return <div className="row">
<div className="col-2"><Menu /></div>
<div className="col-10">
    <div className="box row">
        {(id) ? <div className="col-12 text-end"><a href="/internationals" className="btn btn-sm btn-primary">Go back</a></div> : ''}
        <div className="form-group col-4">
            <label>Enter a title for the Page</label>
            <input type="text" ref={title} className="form-control" />
        </div>
        <div className="form-group col-2">
            <label>URL of the page</label>
            <input type="text" ref={urls} className="form-control" />
        </div>
        <div className="form-group col-6">
            <label>Upload banner image</label>
            <input type="file" ref={picture} className="form-control" />
        </div>
        <div className="col-12 my-3">
            <label>Content</label>
        <SunEditor setContents={content} onChange={setContent} height="200" />
        </div>
        <div className="col-2">
            <span className="btn btn-primary" onClick={savepage}>Save Page</span>
        </div>
    </div>
    <hr />
    <table className="table">
    <thead>
        <tr className="row">
            <th className="col-6">Title of the page</th>
            <th className="col-4">Display picture</th>
            <th className="col-2">Actions</th>
        </tr>
    </thead>
    <tbody>
        {testimonials.map((t) => <tr className="row" key={t.id}><td className="col-4">{t.name}</td><td className="col-4"><img src={`https://assets.thevoicebusiness.com.au/${t.picture}`} className="w-100" /></td>
<td className="col-4 text-end">
    <Link to={`/internationals/${t.id}`} className="btn btn-sm btn-primary">Edit</Link>
<span className="btn btn-danger btn-sm ms-2" onClick={(evt) => deletepage(evt)}>Delete</span>
</td>
        </tr>) }
        </tbody>
    </table>
</div>
</div>
}
