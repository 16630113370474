import { Link, useParams } from "react-router-dom";
import Menu from "./menu";
import { useEffect, useState } from "react";

const Contact = () => {
const {id} = useParams();
const [booking, setBooking] = useState(false);
useEffect(() => {
    if (booking === false) {
        fetch('https://api.thevoicebusiness.com.au/contacts/'+id, {credentials: 'include'})
    .then(res => res.json()).then(jr => setBooking(jr));
    }
}, [id]);
return <div className="row">
<div className="col-md-2">
    <Menu />
    </div>
<div className="col-md-10">
    <div className="row">
    {(!booking) ? <p>Loading...</p> :
    <div className="col-md-9">
        <p className="text-end"><Link to="/contacts">Back to Contacts</Link></p>
        <h5>Contacted on : {new Date(booking.createdAt).toLocaleString()}</h5>
    <table className="table table-bordered table-hover mt-3">
        <tbody>
        <tr><th>Name</th><td>{booking.name}</td></tr>
        <tr><th>Mobile</th><td>{booking.phone}</td></tr>
        <tr><th>Email</th><td>{booking.email}</td></tr>
        <tr><th>Company</th><td>{booking.company}</td></tr>
        <tr><th>Reason to contact</th><td>{booking.reason}</td></tr>
        <tr><th>Specific Reason</th><td>{booking.reason2}</td></tr>
        <tr><th>Requirements</th><td>{booking.specificrequest}</td></tr>
        <tr><th>Message</th><td>{booking.message}</td></tr>
        </tbody>
    </table>
    </div>
    }
    </div>
</div>
</div>}

export default Contact;