import { useEffect, useState } from "react";
import Menu from "./menu";
import { Link } from "react-router-dom";

const Courses = () => {
    const [courses, setCourses] = useState(false);
    const updateSeq = (elem, id, seq) => {
        elem.parentNode.parentNode.classList.add('table-warning');
        fetch('https://api.thevoicebusiness.com.au/update-seq', { method: 'post',
            headers: {'Content-Type': 'application/json'}, credentials: 'include',
            body: JSON.stringify({id: id, seq: seq})
        }).then(res => res.json())
        .then(jr => {
            if (jr.success) {
                elem.parentNode.parentNode.classList.remove('table-warning');
                elem.parentNode.parentNode.classList.add('table-success');
            }
setTimeout(() => elem.parentNode.parentNode.classList.remove('table-success'), 1200);
        });
    }


const delcourse = (evt, id) => {
    if(window.confirm('Are you sure you want to delete this course?')) {
        fetch('https://api.thevoicebusiness.com.au/courses/'+id, { credentials: 'include',
            method: 'DELETE', headers: {'Content-Type':'application/json'} })
        .then(res => {
            evt.target.parentNode.parentNode.remove();
            window.location.href = window.location.href;
        });

    }
}

    useEffect(() => {
        if (courses !== false) { return; }
        fetch('https://api.thevoicebusiness.com.au/courses')
        .then(res => res.json()).then(jr => {
            setCourses(jr)
        });
    }, []);
    return <div className="row">
    <div className="col-md-2">
        <Menu />
    </div>
    <div className="col-md-10">
        <h4>Managing Courses</h4>
        <div className="text-end mb-3">
            <Link to="/courses/0" className="btn btn-primary">Add a new Course</Link>
        </div>
        {(courses) ? <table className="table">
            <thead><tr>
                <th>Sequence</th>
                <th>Title</th>
                <th>Description</th>
                <th>View on Site</th>
                <th>Actions</th>
            </tr></thead>
            <tbody>
{courses.map(el => <tr key={el.id}>
<td><input type="number" className="form-control numlimit" onBlur={(e) => updateSeq(e.target, el.id, e.target.value)} defaultValue={el.sequence} /></td>
<td>{el.title}</td>
<td>{el.description}</td>
<td><a href={"https://www.thevoicebusiness.com.au/courses/"+el.url} target="_blank">Click to view</a></td>
<td><Link className="btn btn-primary" to={"/courses/"+el.id}>Edit</Link>
<div onClick={(evt) => {evt.target.parentNode.parentNode.classList.add('table-warning');delcourse(evt,el.id);}} className="d-inline pointer"><img src="/bin.svg" alt="delete" /></div>
</td>
</tr>)}
            </tbody>
        </table>: <>loading...</>}
    </div>
    </div>
}

export default Courses;