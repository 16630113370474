import { useState, useEffect } from "react";
import Menu from "./menu";
import { Link } from "react-router-dom";

const C = () => {
  const [contacts, setContacts] = useState(false);
  const delcontact = (evt,id) => {
    setTimeout(() => {
    if (window.confirm("Are you sure you want to delete this?")) {
        fetch('https://api.thevoicebusiness.com.au/contact', {
method: 'delete', body: JSON.stringify({id: id}), headers: {'Content-Type':'application/json'},
credentials: 'include'
        }).then(res => res.json())
        .then(jr => evt.target.parentNode.parentNode.remove());
    } else {
        evt.target.parentNode.parentNode.classList.remove('table-warning');
    }
}, 1);
}
  useEffect(() => {
    if (contacts === false) {
      fetch('https://api.thevoicebusiness.com.au/contacts', {credentials: 'include', headers: {
        'Content-Type': 'application/json' }})
        .then(res => res.json())
        .then(jr => setContacts(jr));
    }
  }, []);
  return <div className="row">
    <div className="col-md-2">
    <Menu /></div>
    <div className="col-md-10">
    <h4 className="mb-3">Contacts</h4>
    {(contacts) ? <table className="table">
<thead><tr><th>Name</th><th>Email</th><th>Contacted on</th><th>Actions</th></tr></thead>
<tbody>{
contacts.map(el => <tr key={el.id}><td>{el.name}</td><td>{el.email}</td>
<td>{new Date(el.createdAt).toDateString()}</td><td>
  <Link to={"/contacts/"+el.id} className="btn btn-primary">View</Link> <div onClick={(evt) => {evt.target.parentNode.parentNode.classList.add('table-warning');
                delcontact(evt,el.id);}} className="d-inline pointer"><img src="/bin.svg" alt="delete" /> Delete</div>
</td>
</tr>)
}</tbody>
    </table>: <p>Loading...</p>}
    </div>
    </div>
}

export default C;