import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Menu = () => {
  useEffect(() => {
    fetch('https://api.thevoicebusiness.com.au/check-login', {credentials: 'include', headers: {
      'Content-type':'application/json'}})
  .then(res => (res.status === 200) ? res.json() : false)
  .then((jr) => { if (!jr.success) { window.location.href = "/"; } });
  }, []);
return <aside className="vstack gap-2 mx-auto">
<NavLink to="/bookings" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Bookings</NavLink>
<NavLink to="/contacts" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Contacts</NavLink>
<NavLink to="/courses" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Manage Courses</NavLink>
<p className="my-3 bg-dark text-center p-3 text-white">Edit Pages:</p>
<NavLink to="/edit-home" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Home Page</NavLink>
<NavLink to="/edit-home-testimonials" className={({isActive}) => isActive ? 'btn btn-primary' : 'btn btn-outline-primary'}>Testimonials</NavLink>
<NavLink to="/edit-about" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>About Page</NavLink>
<NavLink to="/edit-terms" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Terms & Cond<sup>n</sup></NavLink>
<NavLink to="/edit-privacy" className={({isActive}) => isActive ? "btn btn-primary" : "btn-outline-primary btn" }>Privacy Policy</NavLink>
<NavLink to="/press-blog" className={({isActive}) => isActive ? 'btn btn-primary' : 'btn-outline-primary btn'}>Press / Blog</NavLink>
<NavLink to="/internationals" className={({isActive}) => isActive ? 'btn btn-primary' : 'btn-outline-primary btn'}>International Pages</NavLink>
<button onClick={() => {
  fetch('https://api.thevoicebusiness.com.au/logout', {credentials: 'include'})
  .then(res => res.json()).then(jr => window.location.href = '/');
}} className="btn btn-light">Logout</button>
</aside>}

export default Menu;