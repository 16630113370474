import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";

const Bookings = () => {
    const [bookings, setBookings] = useState([]);
    
    useEffect(() => {
        fetch('https://api.thevoicebusiness.com.au/bookings', {credentials: 'include'})
    .then(res => res.json())
    .then(jr => setBookings(jr));
    }, []);
    const delbooking = (evt,id) => {
        setTimeout(() => {
        if (window.confirm("Are you sure you want to delete this?")) {
            fetch('https://api.thevoicebusiness.com.au/booking', {
method: 'delete', body: JSON.stringify({id: id}), headers: {'Content-Type':'application/json'},
credentials: 'include'
            }).then(res => res.json())
            .then(jr => evt.target.parentNode.parentNode.remove());
        } else {
            evt.target.parentNode.parentNode.classList.remove('table-warning');
        }
    }, 1);
    }
    return <div className="row">
    <div className="col-md-2">
    <Menu />
    </div>
    <div className="col-md-10">
    <h4 className="mb-3">Bookings</h4>
    <table className="table">
        <thead>
        <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {bookings.map((el, i) => <tr key={i}>
            <td>{el.firstname}</td>
            <td>{el.lastname}</td>
            <td>{el.email}</td>
            <td>{el.mobile}</td>
            <td>
                <Link to={"/bookings/"+el.id} className="btn btn-primary mx-2">View</Link>
                <div onClick={(evt) => {evt.target.parentNode.parentNode.classList.add('table-warning');
                delbooking(evt,el.id);}} className="d-inline pointer"><img src="/bin.svg" alt="delete" /> Delete</div>
            </td>
        </tr>)}
        </tbody>
    </table>
    </div>
    </div>
}

export default Bookings;