import { useEffect, useRef, useState } from "react";
import Ct from "./ct";
import Menu from "./menu";
import { Link, useParams } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const Course = () => {
const {id} = useParams();
const [loading, setLoading] = useState(true);
const [schedule, setSchedule]= useState('');
const [lo, setLo] = useState('');
const [courseData, setCoursedata] = useState({title:'', description:'', keywords: ''});
const displaypicture = useRef();
const banner = useRef();
useEffect(() => {
    if (!loading) { return; }
    if (parseInt(id) > 0) {
        fetch('https://api.thevoicebusiness.com.au/courses/'+id, {credentials: 'include'})
        .then(res => res.json())
        .then(jr => { setLoading(false);
        setCoursedata({ title: jr.title, description: jr.description, keywords: jr.keywords });
        setLo(jr.learningoutcomes); setSchedule(jr.schedule);
        setTimeout(() => document.querySelector('.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable').classList.add('coursedetails'), 1);
    });
    } else {
        setLo("");
        setLoading(false);
    }
},[id]);

const [testimonials, setTestimonials] = useState([]);
const addTestimonial = () => {
    setTestimonials([...testimonials, <></>]);
}

const savecourse = () => {
    let formdata = new FormData();
    if(displaypicture.current.files.length > 0) {
        formdata.append('dp', displaypicture.current.files[0]);
    }
    if(banner.current.files.length > 0) {
        formdata.append('background', banner.current.files[0]);
    }
    formdata.append('title', courseData.title);
    formdata.append('description', courseData.description);
    formdata.append('keywords', courseData.keywords);
    formdata.append('learningoutcomes', lo);
    formdata.append('schedule', schedule);
    let url = ''; let method = '';
    if (parseInt(id) > 0) { url = 'https://api.thevoicebusiness.com.au/courses/'+id; method = 'put'; }
    else { url = 'https://api.thevoicebusiness.com.au/courses'; method="post"; }
    fetch(url, {method: method, body: formdata, credentials: 'include'})
    .then((r) => r.json()).then(jr => (jr.success) ? alert('Saved') : alert('Could not be saved!'));
}
return <div className="row">
    <div className="col-md-2"><Menu /></div>
    <div className="col-md-10">
        <div className="text-end"><Link to="/courses">Back to courses</Link></div>
        <hr />
    <div className="mt-3">
        {(loading) ? <p>please wait...</p> : <>
        <div className="form-group">
            <h4>Title of the course</h4>
            <input id="title" className="form-control form-control-lg" defaultValue={courseData.title} placeholder="Enter title for the course"
            onBlur={(e) => setCoursedata((prev) => ({...prev, title: e.target.value}))} />
        </div>
        <div className="row">
            <div className="col-6">
        <div className="form-group bg-info-subtle">
            <h5>Display picture of the course</h5>
            <input type="file" className="form-control-lg" ref={displaypicture} />
        </div></div><div className="col-6">
        <div className="form-group">
            <h5>Banner picture of the course</h5>
            <input type="file" className="form-control-lg" ref={banner} />
        </div></div>
        </div>
        <div className="form-group my-3">
            <h4>Description of the course (keep it under 200 characters)</h4>
            <textarea rows={3} id="description" defaultValue={courseData.description}
            onBlur={(e) => setCoursedata((prev) => ({...prev, description: e.target.value}))}
            className="form-textarea form-control"></textarea>
        </div>
        <div className="form-group my-3">
            <h4>Keywords of the course</h4>
            <textarea rows={3} id="keywords" defaultValue={courseData.keywords}
            onBlur={(e) => setCoursedata((prev) => ({...prev, keywords: e.target.value}))}
            className="form-textarea form-control"></textarea>
        </div>
        <div className="form-group pb-3 my-3">
            <h4>Course Details</h4>
            <SunEditor setContents={lo} rows={10} onChange={setLo} />
        </div>
        <div className="form-group pb-3 my-3">
            <h4>Schedule & Cost</h4>
            <SunEditor setContents={schedule} rows={10} onChange={setSchedule} />
        </div>
        <div className="form-group">
            <button className="btn btn-primary btn-lg" onClick={savecourse}>Save Course Information</button>
        </div>
        <hr />
        <h2>Testimonials</h2>
        {testimonials.map((el) => <Ct key={el.id} {...el} />)}
        {
            (testimonials.length > 3) ? <></> : <button onClick={() => {addTestimonial()}}>Add more testimonial</button>
        }
        </>
        }
    </div>
    </div>
</div>
}

export default Course;
