import { useParams } from "react-router-dom";
import Menu from "./menu";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default () => {
const [_u, u_] = useState([]);
const name = useRef();
const picture = useRef();
const testimonial = useRef();
const {id} = useParams();

const ___u = 'https://api.thevoicebusiness.com.au/testimonials';

const addTestimonial = () => {
const fd = new FormData(); fd.append('name', name.current.value);
if (picture.current.files.length > 0) { fd.append('picture', picture.current.files[0]); }
const method = (id) ? 'PUT' : 'POST';

fd.append('testimonial', testimonial.current.value);
let url = (id) ? `${___u}/${id}` : ___u;
fetch(url, { method: method, body: fd, credentials: 'include'})
.then(_ => window.location.href = '/edit-home-testimonials');
}

useEffect(() => { if (!id) return;
    window.scroll(0,0);
fetch(___u + `/${id}`).then(r => r.json())
.then(jr => { name.current.value = jr.name; testimonial.current.value = jr.testimonial; console.log(jr) });
}, [id]);


const ___ = () => {fetch(___u).then(_ => _.json()).then(__ => u_(__));};
useEffect(___,[]);

return <div className="row">
<div className="col-2">
<Menu />
</div>
<div className="col-8">
    <div className="row">
        {(id) ? <div className="col-12"><Link to="/edit-home-testimonials" className="btn btn-primary btn-sm">go back</Link></div>: ''}
        <div className="col-md-6">
            <div className="form-group">
                <label htmlFor="picture">Upload picture</label>
                <input type="file" className="form-control" ref={picture} id="picture" />
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input className="form-control" id="name" ref={name} />
            </div>
        </div>
        <div className="col-md-12">
            <div className="form-group mb-2">
            <label htmlFor="testimonial">Enter testimonial</label>
            <textarea id="testimonial" className="form-control" ref={testimonial} style={{height: '150px'}}></textarea>
            </div>
            <div className="mt-2">
                <button id="submit" className="btn btn-primary" onClick={addTestimonial}>Submit</button>
            </div>
        </div>
    </div>
    <div className="p-2 mt-4">
    { _u.map((u) => <div className="row p-2 my-3 border rounded">
            <div className="col-4">
                <img src={`https://assets.thevoicebusiness.com.au/${u.picture}`} className="w-100" />
            </div>
            <div className="col-6">
                <div>{u.testimonial}</div>
                <h4>{u.name}</h4>
            </div>
            <div className="col-2">
                <Link to={`/edit-home-testimonials/${u.id}`} className="btn btn-primary btn-sm">Edit Testimonial</Link>
            </div>
        </div>) }
    </div>
</div>
</div>
}