import SunEditor from 'suneditor-react';

const Pagecontent = (props) => {
    const updatecontent = (sm) => {
        let px = {}; px.content = {};
        px.content[props.el] = sm;
        props.setcontent((prev) => {
            prev.content[props.el] = sm;
            return prev;
        });
    }
    
return <div key={props.ea} className='py-2'><p>{props.el}</p>
<SunEditor defaultValue={props.content[props.el]} onChange={updatecontent} />
</div>
}

export default Pagecontent;